<template>
  <div class="md-layout">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.md-layout {
  position: relative;
  z-index: 2;
}
</style>
